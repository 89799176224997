<template>
  <div class="content">
    <Header></Header>
    <Bread></Bread>
    <Shipment v-if="select === 1" style="margin-top: 10px"></Shipment>
    <Production v-if="select === 2" style="margin-top: 10px"></Production>
  </div>
</template>

<script>
import { EventBus } from "../event-bus"
import Header from "../components/Header"
import Shipment from "../components/Shipment"
import Bread from "../components/Bread"
import Production from "../components/Production";


export default {
  name: "Main",
  components: {Production, Shipment, Bread, Header},

  data(){
    return {
      select:1, //默认选择为1
      selectClickData:null //默认为空
    }
  },
  mounted(){
    EventBus.$on("selectClick",(data)=>{
      console.log("你接受的数据为："+data.selectClickData);
      switch (data.selectClickData) {
        case "Shipment":
          this.select = 1
          break
        case "Production":
          this.select = 2
          break
      }
    });
  },
}

</script>

<style scoped>

.content{
  width: 100%;
  margin: 0;
  padding: 0;
}

</style>