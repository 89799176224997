<template>
  <div class="content">
    <!--头部-->
    <div class="head">
      <div>
        <div class="loginLeftName">
          <img src="../assets/radianceImage.png"  alt="picture"/>
        </div>
      </div>
    </div>

    <!--面包顺-->
    <div class="bread">
      <div class="bread_left">


        <el-button @click="goHome" plain>Shipment</el-button>
        <el-button @click="goProducts" plain>In Production</el-button>

<!--        <el-breadcrumb separator="/" class="bread_el">-->
<!--          <el-breadcrumb-item :to="{ path: '/home' }">Shipment</el-breadcrumb-item>-->
<!--          <el-breadcrumb-item>In Production</el-breadcrumb-item>-->
<!--        </el-breadcrumb>-->
      </div>
      <div class="bread_right">

        <el-button @click="modifyDialogShow" plain>Modify Password</el-button>
        &nbsp;&nbsp;<el-button  @click="loginOut" plain>LogOut</el-button>

        <el-dialog title="Modify Password" :visible.sync="dialogFormVisible" center width="30%">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
            <el-form-item label="Old Password" :label-width="formLabelWidth">
              <el-input v-model="ruleForm.oldpassword"></el-input>
            </el-form-item>
            <el-form-item label="New Password" :label-width="formLabelWidth">
              <el-input v-model="ruleForm.password"></el-input>
            </el-form-item>
            <el-form-item label="Confirm Password" :label-width="formLabelWidth">
              <el-input v-model="ruleForm.cpassword"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">Cancel</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">Confirm</el-button>
          </div>
        </el-dialog>

      </div>
    </div>

    <div class="query">

      <el-form name="searchForm" ref="searchForm" :model="form" size="mini" style="visibility: inherit">
        <div class="search">
          <el-form-item label="" style="width: 100%; padding: 10px;">
<!--            <el-button type="primary" style="float: left; height: 30px;" icon="el-icon-download" @click="exportExcel" >Excel</el-button>-->
            <el-input v-model="searches"
                      size="mini"
                      prefix-icon="el-icon-search"
                      placeholder="Please input Order No."
                      style="width: 230px;
                      cursor: pointer"
                      clearable
                      @clear="clearTable"
                      v-if="false"
            />
            <!-- <el-button style="margin-left: 20px;" type="primary" @click="submitForm('ruleForm')" :disabled="saveDisable">Search</el-button>-->
          </el-form-item>
        </div>
      </el-form>
    </div>

      <div class="chart">
        <div class="print-button-container" style="float: right; margin-right: 50px; margin-bottom: 10px;">
          <el-button type="primary" icon="el-icon-document" @click="exportExcel">Excel</el-button>
        </div>
        <el-table :data="allDeparts"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  border style="width: 100%; text-align: center" id=""
                  :row-key="getRowKeys"
                  :expand-row-keys="expandRows"
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        >
          <template slot="empty">
            <el-empty :image-size="100" description="No data"/>
          </template>

          <el-table-column prop="id" label="ID">
          </el-table-column>
          <el-table-column prop="gno" label="PO No.">
          </el-table-column>
          <el-table-column prop="pi" label="PI No.">
          </el-table-column>
          <el-table-column prop="gmodel" label="TMW mode No.">
          </el-table-column>
          <el-table-column prop="cno" label="Radiance Model No.">
          </el-table-column>
          <el-table-column prop="sl" label="QTY">
          </el-table-column>
          <el-table-column prop="crq" label="Estimated shipping date">
          </el-table-column>
          <el-table-column prop="pno" label="Radiance lot no.">
          </el-table-column>

          <!--新增的字段-->
          <el-table-column label="Production Status">
            <el-table-column prop="spook" label="Materials Ready">
              <template slot-scope="scope">
                <el-image style="width: 20px; height: 20px;" :src="handleImage(scope.row.spook)"/>
              </template>
            </el-table-column>
            <el-table-column prop="ssc" label="In Production">
              <template slot-scope="scope">
                <el-image style="width: 20px; height: 20px;" :src="handleImage(scope.row.ssc)"/>
              </template>
            </el-table-column>

            <el-table-column prop="stest" label="Under Testing">
              <template slot-scope="scope">
                <el-image style="width: 20px; height: 20px;" :src="handleImage(scope.row.stest)"/>
              </template>
            </el-table-column>

            <el-table-column prop="stok" label="Complete Testing">
              <template slot-scope="scope">
                <el-image style="width: 20px; height: 20px;" :src="handleImage(scope.row.stok)"/>
              </template>
            </el-table-column>
          </el-table-column>

        </el-table>
        <el-pagination class="mpage_dl" background layout="prev, pager, next" :current-page="currentPage"
                       :page-size="pageSize" :total="total" @current-change=page>
        </el-pagination>
      </div>

    </div>



</template>


<script>


export default {
  name: "Products",
  // 监控数组变化
  computed:{
    allDeparts:function (){
      //转为大写
      const searchValue = this.searches;
      if(searchValue){
        let treeData = this.departs;
        let handleTreeData = this.handleTreeData(treeData, searchValue.toUpperCase())
        this.setExpandRow(handleTreeData)
        return handleTreeData
      }
      return this.departs;
    }
  },

  data(){
    return {
      // 当前的面包顺序
      currentName:"",
      //博客的
      currentPage: 1,
      cIndex:0,
      total: 0,
      pageSize: 20,
      form:{
        orderNumber:'',
      },
      user:{
        userId:"0",
        username:'',
        password:''
      },
      products:{
        id:0,
        pi:"",
        sl:0,
        crq:"",
        ch:"",
        lx:"",
        gmodel:"",
        gno:"",
        cno:"",
        gname:"",
        pno:"",
        // 2022/12/15新增4个字段
        spook:"",
        ssc:"",
        stest:"",
        stok:"",

        spookcheck:"",
        ssccheck:"",
        stestcheck:"",
        stokcheck:""

      },
      departs:[],
      expandRows:[],
      // 新增搜索的 2022-9-9
      searches:'',

      // 2022 12/21 新增
      dialogFormVisible:false,
      formLabelWidth:"150px",
      ruleForm: {
        oldpassword:'',
        password: '',
        cpassword:''
      },
      rules: {
        password: [
          { required: true, message: 'Please input Password', trigger: 'blur' },
          { min: 3, max: 15, message: 'The length is 3 to 15 char', trigger: 'blur' }
        ],
        cpassword: [
          { required: true, message: 'Please input Confirm Password', trigger: 'blur' },
          { min: 3, max: 15, message: 'The length is 3 to 15 char', trigger: 'blur' }
        ],
      }

    }
  },
  methods:{
      page(currentPage){
        const _this = this;

        _this.$axios.get("product/list?currentPage="+currentPage+"&currentSize="
            +_this.pageSize+"&userId="+_this.user.userId+"&gName="+_this.user.username+"&lx=scd",
            {headers:{
                "Authorization":localStorage.getItem("token")}}
        ).then( res =>{
          if(res.data.data.records.length > 0 ) {

            // 获取网络数据
            const list = res.data.data.records

            //console.log("第一次读取数据: "+JSON.stringify(list))

            _this.cIndex = res.data.data.current;

            let cIndex = res.data.data.current;


            if (_this.departs.length > 0) {
              _this.departs = [];
            }

            let num = 0;
            for (let i = 0; i < list.length; i++) {
              if (cIndex === 1) {
                num = i + 1;
              } else {
                num = cIndex * 10 + i + 1 + ((cIndex - 1) * 10 - 10);
              }

                let product = {}
                product.id = num
                product.gno = list[i].gno
                product.pi = list[i].pi
                product.sl = list[i].sl
                product.crq = this.changesTime(list[i].crq)
                product.ch = list[i].ch
                product.lx = list[i].lx
                product.gmodel = list[i].gmodel
                product.cno = list[i].cno
                product.pno = list[i].pno
                product.spook = list[i].spook
                product.ssc = list[i].ssc
                product.stest = list[i].stest
                product.stok = list[i].stok

                _this.departs.push(product);
              }

              console.log("读取数据:"+_this.departs.length);

              _this.currentPage = res.data.data.currentPage;
              _this.total = res.data.data.total;
              _this.pageSize = res.data.data.size;

          }else{
            this.$message.error('Is empty data!');
          }
        })
      },

    // 时间处理
    changesTime(date) {
        if(date != null) {
          const json_date = new Date(date);
          const monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug",
            "Sep", "Oct", "Nov", "Dec"];
          const month = json_date.getMonth();
          let day = json_date.getDate();
          if (day.toString().length === 1) {
            day = "0" + day.toString();
          }
          return day + " " + monthArray[month] + " " + json_date.getFullYear()
        }else{
          return "Unconfirmed"
        }
    },

    getRowKeys(row) {
      return row.id;
    },
    clearTable(){
      return this.departs;
    },

    //  树形表格过滤
    handleTreeData(treeData, searchValue) {
      if (!treeData || treeData.length === 0) {
        return [];
      }
      const array = [];
      for (let i = 0; i < treeData.length; i += 1) {
        let match = false;
        for(let pro in treeData[i]){
          if(typeof(treeData[i][pro])=='string'){
            match |= treeData[i][pro].includes(searchValue);
            if(match) break;
          }
        }
        if (this.handleTreeData(treeData[i].children, searchValue).length > 0 || match ) {
          array.push({
            ...treeData[i],
            children: this.handleTreeData(treeData[i].children, searchValue),
          });
        }
      }
      return array;
    },
    // 将过滤好的树形数据展开
    setExpandRow(handleTreeData) {
      if(handleTreeData.length) {
        for (let i of handleTreeData) {
          this.expandRows.push(i.id)
          if(i.children.length) {
            this.setExpandRow(i.children)
          }
        }
      }
    },
    // 面包中点击
    breadClick(){
        if(this.$route.path !== "/products"){
          this.$router.push("/home");
        }
    },

    exportExcel(){
      require.ensure([], () => {
        const {
          export_json_to_excel
        } = require("../excel/Export2Excel");
        const tHeader = ["ID", "PO NO.", "PI No.", "TMW Model No.", "Radinace Model No.", "QTY",
          "Estimated shipping date","Radiance Iot No.","Materials Ready","In Production","Under Testing","Complete Testing"]; // 这是excel文档第一行显示的标题
        const filterVal = ["id", "gno", "pi", "gmodel", "cno", "sl", "crq", "pno","spook","ssc","stest","stok"]; // id，version等都是上面标题所对应的数据
        const list = this.departs; //把data里的数组tableData存到list中
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "In Production Table Excel"); //标题，数据，文件名
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },

    /**
     * 调用处理程序
     */
    handleImage(value){
      if(value === "YES"){
        return require("../assets/selected.png")
      }else{
        return require("../assets/unselect.png")
      }
    },

    /**
     * 调用浏览器的打印方法
     */
    handlePrint() {
      window.print()
    },

    /**
     * 调用修改密码弹窗
     */
    modifyDialogShow(){
      this.dialogFormVisible = true
    },

    submitForm(formName) {
      this.dialogFormVisible = false

      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this

          if(_this.ruleForm.password === _this.ruleForm.cpassword) {

            const updatePwd = {
              "username":_this.user.username,
              "password":_this.ruleForm.password
            }

            // 判断必须要相等才可以
            if(_this.user.password === _this.ruleForm.oldpassword) {
              _this.$axios.post("user/modifyPwd", updatePwd, {
                headers: {
                  "Authorization": localStorage.getItem("token")
                }
              }).then(
                  res => {
                    console.log(res.data.username)
                    this.$message.success("Modify success")
                  }
              )
            }else{
              this.$message.error("The old password error!")
            }
          }else{
            this.$message.error('The confirm password need as seem as compare password !');
          }
        } else {
          this.$message.error('The account or password error!');
        }
      });
    },

    // 退出登录
    loginOut(){
      this.$axios.get("user/logout",{headers:{
          "Authorization":localStorage.getItem("token")}}).then(
          res =>{
            console.log(res.data.message)
            //this.$message.success(res.data.message)

            // 跳转到登录界面
            this.$router.push("/login");
          }
      )
    },

    goHome(){
      this.$router.push("/home");
    },

    goProducts(){
      this.$router.push("/products");
    },

  },

  created() {
    // 获取当前的名称
    this.currentName = "Products"

    if(this.$store.getters.getUser.id){
      this.user.userId = this.$store.getters.getUser.id
      this.user.username = this.$store.getters.getUser.username
      // 获取密码
      this.user.password = this.$store.getters.getUser.password
      // 获取数据
      this.page(1)
    }
  }
}
</script>




<style scoped>

.content{
  width: 100%;
  margin: 0;
  padding: 0;
}

.head{
  width: 100%;
}

.bread{
  width: 100%;
  height: 60px;
  background-color: #E1E1E1;
  float: left;
  -ms-flex: 1;
  flex: 1;
}

.bread .bread_left{
  width: 25%;
  height: 60px;
  line-height: 60px;
  margin-left: 20px;
  color:#323232;
  font-weight: normal;
  float: left;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.bread .bread_right{
  width: 40%;
  height: 60px;
  line-height: 60px;
  float: right;
  font-weight: normal;
  font-size: 16px;
  text-align: right;
  margin-right: 20px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.query{
  width: 100%;
  margin-top: 30px;
  float: left;
}

.query .search{
  width: 90%;
  text-align: right;
  margin-right: 20px;
}

.query .excel{
  width: 90%;
  text-align: center;
}

.query .chart{
  width: 100%;
}

.mpage_dl {
  margin: 0 auto;
  text-align: center;
}

.chart{
  padding: 8px;
}

.bread .bread_left .bread_el{
  text-align: center;
  line-height: 60px;
}

.currenBtn:hover {
  opacity: 0.8;
}

@media print {
  @page {
    /* 纵向打印 */
    size: auto;

    /* 横向打印 */
    /*size: landscape;*/

    /* 去掉页眉页脚*/
    margin: 3mm;
  }

  .footer {page-break-after: always;}

  /*打印不显示打印按钮*/
  .print-button-container {
    display: none !important;
  }

  /*页数不打印*/
  .mpage_dl {
    display: none !important;
  }

  .bread {
    display: none !important;
  }

  .query {
    display: none !important;
  }
}


</style>
