<template>
  <!--头部-->
  <div class="head">
    <div>
      <div class="loginLeftName">
        <img src="../assets/radianceImage.png"  alt="picture"/>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

.head{
  width: 100%;
}

</style>