<template>

  <div class="chart">

    <div class="print-button-container" style="float: right; margin-right: 50px; margin-bottom: 10px; margin-top: 10px;">
      <el-button type="primary" icon="el-icon-document" @click="exportExcel">Excel</el-button>
    </div>

    <el-table :data="allDeparts"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              border style="width: 100%; text-align: center" id=""
              :row-key="getRowKeys"
              :expand-row-keys="expandRows"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <template slot="empty">
        <el-empty :image-size="100" description="No data"/>
      </template>
      <el-table-column prop="id" label="ID">
        <!--            <template slot-scope="scope">{{scope.$index+1}}</template>-->
      </el-table-column>
      <el-table-column prop="jhdate" label="Planned shipping date">
        <!--            <template slot-scope="scope">{{changesTime(scope.row.jhdate)}}</template>-->
      </el-table-column>
      <el-table-column prop="gno" label="Order No.">
      </el-table-column>
      <el-table-column prop="gmodel" label="Customer Model No.">
      </el-table-column>
      <el-table-column prop="cno" label="Radiance Model No.">
      </el-table-column>
      <el-table-column prop="outsl" label="QTY(PCS)">
      </el-table-column>
      <el-table-column prop="mxsl" label="PCS/CTN">
      </el-table-column>
      <el-table-column prop="zxs" label="Total CTNS">
      </el-table-column>
      <el-table-column prop="mz" label="G.W./CTN">
      </el-table-column>
      <el-table-column prop="mz1" label="Total KGs">
      </el-table-column>
      <el-table-column prop="mz2" label="Total LBs">
      </el-table-column>
      <el-table-column label="Dimension(L*W*H)CM">
        <el-table-column prop="ccm" label="Length"/>
        <el-table-column prop="kcm" label="Width"/>
        <el-table-column prop="gcm" label="Height"/>
      </el-table-column>
      <el-table-column prop="zjz" label="Total Volume(CBM)">
      </el-table-column>
      <el-table-column prop="pno" label="Radiance Lot No.">
      </el-table-column>
    </el-table>
    <el-pagination class="mpage_dl" background layout="prev, pager, next" :current-page="currentPage"
                   :page-size="pageSize" :total="total" @current-change=page>
    </el-pagination>
  </div>

</template>

<script>
export default {
  name: "Shipment",
  // 监控数组变化
  computed:{
    allDeparts:function (){
      //转为大写
      const searchValue = this.searches;
      if(searchValue){
        let treeData = this.departs;
        let handleTreeData = this.handleTreeData(treeData, searchValue.toUpperCase())
        this.setExpandRow(handleTreeData)
        return handleTreeData
      }
      return this.departs;
    }
  },
  data(){
    return {
      currentName:"",
      //博客的
      currentPage: 1,
      total: 0,
      pageSize: 20,
      cIndex:0,
      number:0,
      form:{
        orderNumber:'',
      },
      user:{
        userId:"0",
        username:'',
        password:''
      },
      // 自定义order
      order:{
        id:0,
        jhdate:"",
        gno:"",
        gmodel:"",
        cno:"",
        outsl:"",
        mxsl:"",
        zxs:"",
        mz:"",
        mz1:"",
        mz2:"",
        ccm:"",
        kcm:"",
        gcm:"",
        zjz:"",
        pno:""
        //remark:""
      },
      expandRows:[],
      departs:[],
      // 新增搜索的 2022-9-9
      searches:'',
      columns:[
        {prop:'id',label: 'ID'},
        {prop:'date', label:'Planned shipping date'},
        {prop: 'order', label: 'Production order number'},
        {prop: 'number', label: 'Production number'},
        {prop: 'type', label: 'Customer Model No.'},
        {prop: 'quantity', label: 'Export quantity'},
        {prop: 'customer', label: 'Order number'},
        {prop: 'length', label: 'Outer box length'},
        {prop: 'width', label: 'Outer box width'},
        {prop: 'height', label: 'Outer box height'},
        {prop: 'amount', label: 'Amount per box'},
        {prop: 'gross', label: 'Gross weight'},
        {prop: 'net', label: 'Net weight'},
        {prop: 'single', label: 'Single net weight'},
        {prop: 'battery', label: 'battery model'},
        {prop: 'total', label: 'Total number boxes'},
        {prop: 'gross-weight', label: 'Gross weight'},
        {prop: 'total-weight', label: 'Total net weight'},
        {prop: 'cubic', label: 'Total cubic meter'},
        {prop: 'case-number', label: 'Case number'},
        {prop: 'ship', label: 'Whether to ship'},
      ],
    }
  },
  // 方法
  methods: {
    // 获取当前的页数
    page(currentPage) {

      const _this = this;

      _this.$axios.get("guest/products?currentPage=" + currentPage + "&currentSize=" + _this.pageSize
          + "&userId=" + _this.user.userId + "&gname=" + _this.user.username + "&chok=NO",
          {
            headers: {
              "Authorization": localStorage.getItem("token")
            }
          })
          .then(res => {
            if (res.data.data.records.length > 0) {
              const list = res.data.data.records;
              _this.cIndex = res.data.data.current;

              let cIndex = res.data.data.current;
              //_this.departs = [..._this.departs, ...list];
              if (_this.departs.length > 0) {
                _this.departs = [];
              }

              let num = 0;

              for (let i = 0; i < list.length; i++) {
                if (cIndex === 1) {
                  num = i + 1;
                } else {
                  num = cIndex * 10 + i + 1 + ((cIndex - 1) * 10 - 10);
                }
                // 进行数据处理
                let order = {};
                //const o = _this.order();
                order.id = num;
                order.jhdate = this.changesTime(list[i].jhdate);

                order.gno = list[i].gno;
                order.cno = list[i].cno;
                order.gmodel = list[i].gmodel;

                order.outsl = list[i].outsl;
                order.mxsl = list[i].mxsl;
                order.zxs = list[i].zxs;
                order.mz = this.numFilterMap(list[i].mz, 1);
                order.mz1 = this.numFilterMap(this.numCombine(list[i].zxs, list[i].mz), 1);
                order.mz2 = this.numFilter(order.mz1 * 2.2);
                order.ccm = list[i].ccm;
                order.kcm = list[i].kcm;
                order.gcm = list[i].gcm;
                order.zjz = list[i].zjz;
                order.pno = list[i].pno;
                order.remark = "";

                _this.departs.push(order);
              }

              _this.currentPage = res.data.data.currentPage;
              _this.total = res.data.data.total;
              _this.pageSize = res.data.data.size;

            } else {
              this.$message.error('Is empty data!');
            }
          });
    },
    // 自动计算id
    computerId(index) {
      this.number = index + 1;
      // if(list.length > 0){
      //   for(let i =0; i<list.length; i++){
      //     if(this.cIndex === 1){
      //       this.number = i + 1;
      //     }else{
      //       this.number = this.cIndex*10+i+1+((this.cIndex-1)*10-10);
      //     }
      //   }
      // }
      return this.number;
    },
    // 搜索数据
    search(currentPage) {
      const _this = this;
      _this.$axios.get("guest/products?currentPage=" + currentPage + "&currentSize=" + _this.pageSize
          + "&userId=" + _this.user.userId + "&gname=" + _this.user.username + "&chok=NO",
          {
            headers: {
              "Authorization": localStorage.getItem("token")
            }
          })
          .then(res => {
            if (res.data.data.records.length > 0) {
              _this.departs = res.data.data.records;
              console.log("length is:" + JSON.stringify(_this.departs.length));
            } else {
              console.log("读取数据错误");
            }
            _this.currentPage = res.data.data.currentPage;
            _this.total = res.data.data.total;
            _this.pageSize = res.data.data.size;
          });
    },

    // 搜索数据
    searchNo() {

    },

    getRowKeys(row) {
      return row.id;
    },
    clearTable() {
      return this.departs;
    },
    //  树形表格过滤
    handleTreeData(treeData, searchValue) {
      if (!treeData || treeData.length === 0) {
        return [];
      }
      const array = [];
      for (let i = 0; i < treeData.length; i += 1) {
        let match = false;
        for (let pro in treeData[i]) {
          if (typeof (treeData[i][pro]) == 'string') {
            match |= treeData[i][pro].includes(searchValue);
            if (match) break;
          }
        }
        if (this.handleTreeData(treeData[i].children, searchValue).length > 0 || match) {
          array.push({
            ...treeData[i],
            children: this.handleTreeData(treeData[i].children, searchValue),
          });
        }
      }
      return array;
    },
    // 将过滤好的树形数据展开
    setExpandRow(handleTreeData) {
      if (handleTreeData.length) {
        for (let i of handleTreeData) {
          this.expandRows.push(i.id)
          if (i.children.length) {
            this.setExpandRow(i.children)
          }
        }
      }
    },

    // 固定行 表示总和
    getSummaries(param) {
      if (this.departs.sum !== null) {
        // , data
        const {columns, data} = param;
        const sums = [];
        //const arr = ['mz1', 'mz2'];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Total';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              // console.log(prev)
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = parseFloat(sums[index]).toFixed(2);
            sums[index] += ' ';
          } else {
            sums[index] = 'N/A';
          }
        });

        return sums;

      }
    },

    exportExcel() {
      require.ensure([], () => {
        const {
          export_json_to_excel
        } = require("../excel/Export2Excel");
        const tHeader = ["ID", "Planned shipping date", "Order No.", "Customer Model No.", "Radinace Model No.", "QTY(PCS)",
          "PCS/CTN", "Total CTNS", "G.W./CTN", "Total KGs", "Total LBs", "Dimension(Length) CM",
          "Dimension(Width) CM", "Dimension(Height) CM", "Total Volume(CBM)", "Radiance Lot No."]; // 这是excel文档第一行显示的标题
        const filterVal = ["id", "jhdate", "gno", "gmodel", "cno", "outsl", "mxsl", "zxs",
          "mz", "mz1", "mz2", "ccm", "kcm", "gcm", "zjz", "pno"]; // id，version等都是上面标题所对应的数据
        const list = this.departs; //把data里的数组tableData存到list中
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "Home Table Excel"); //标题，数据，文件名
      });
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));

    },

    // 时间处理
    changesTime(date) {
      if (date != null) {
        const json_date = new Date(date);
        const monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug",
          "Sep", "Oct", "Nov", "Dec"];
        const month = json_date.getMonth();
        let day = json_date.getDate();
        if (day.toString().length === 1) {
          day = "0" + day.toString();
        }
        return day + " " + monthArray[month] + " " + json_date.getFullYear()
      } else {
        return "Unconfirmed"
      }
    },

    // 保留2位小数
    numFilter(value) {
      return parseFloat(value).toFixed(2);
    },

    numFilterMap(value, num) {
      return parseFloat(value).toFixed(num);
    },

    // 组合数据 保留2位小数
    numCombine(value1, value2) {
      if (value1 == null) {
        value1 = 0;
      }

      if (value2 == null) {
        value2 = 0;
      }

      return value1 * value2;
    },
  },
  created() {
    // 获取当前的名称
    this.currentName = "Home"

    if(this.$store.getters.getUser.id){
      this.user.userId = this.$store.getters.getUser.id
      this.user.username = this.$store.getters.getUser.username
      // 获取密码
      this.user.password = this.$store.getters.getUser.password

      // 获取数据
      this.page(1);
    }
  }
}
</script>

<style scoped>

.query .chart{
  width: 100%;
}

.mpage_dl {
  margin: 0 auto;
  text-align: center;
}

.chart{
  padding: 8px;
}

</style>