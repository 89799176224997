<template>
  <div class="content">
    <!--头部-->
    <div class="head">
      <div>
        <div class="loginLeftName">
          <img src="../assets/radianceImage.png"  alt="picture"/>
        </div>
      </div>
    </div>
    <!--面包顺-->
    <div class="bread">
      <div class="bread_left">
        <el-button @click="goHome" plain>Shipment</el-button>

        <el-button @click="goProducts" plain v-show="isDisPlayProducts">In Production</el-button>

      </div>
    <div class="bread_right">

      <el-button @click="modifyDialogShow" plain>Modify Password</el-button>
      &nbsp;&nbsp;<el-button  @click="loginOut" plain>LogOut</el-button>

      <el-dialog title="Modify Password" :visible.sync="dialogFormVisible" center width="30%">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item label="Old Password" :label-width="formLabelWidth">
            <el-input v-model="ruleForm.oldpassword"></el-input>
          </el-form-item>
          <el-form-item label="New Password" :label-width="formLabelWidth">
            <el-input v-model="ruleForm.password"></el-input>
          </el-form-item>
          <el-form-item label="Confirm Password" :label-width="formLabelWidth">
            <el-input v-model="ruleForm.cpassword"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">Confirm</el-button>
        </div>
      </el-dialog>
     </div>
    </div>
    <!--内容区域-->
    <div class="query">
      <el-form name="searchForm" ref="searchForm" :model="form" size="mini" style="visibility: inherit">
        <div class="search">
          <el-form-item label="" style="width: 100%; padding: 10px;">
<!--            <el-button type="primary" style="float: left; height: 30px;" icon="el-icon-download" @click="exportExcel" >Excel</el-button>-->
            <el-input v-model="searches"
                      size="mini"
                      prefix-icon="el-icon-search"
                      placeholder="Please input Order No."
                      style="width: 230px;
                      cursor: pointer"
                      clearable
                      @clear="clearTable"
                      v-if="false"
                      />
          </el-form-item>
        </div>
      </el-form>
    </div>

      <div class="chart">

        <div class="print-button-container" style="float: right; margin-right: 50px; margin-bottom: 10px;">
          <el-button type="primary" icon="el-icon-document" @click="exportExcel">Excel</el-button>
        </div>

        <el-table :data="allDeparts"
                  :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}"
                  border style="width: 100%; text-align: center" id=""
                  :row-key="getRowKeys"
                  :expand-row-keys="expandRows"
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        >
          <template slot="empty">
            <el-empty :image-size="100" description="No data"/>
          </template>
          <el-table-column prop="id" label="ID">
<!--            <template slot-scope="scope">{{scope.$index+1}}</template>-->
          </el-table-column>
          <el-table-column prop="jhdate" label="Planned shipping date">
<!--            <template slot-scope="scope">{{changesTime(scope.row.jhdate)}}</template>-->
          </el-table-column>
          <el-table-column prop="gno" label="Order No.">
          </el-table-column>
          <el-table-column prop="gmodel" label="Customer Model No.">
          </el-table-column>
          <el-table-column prop="cno" label="Radiance Model No.">
          </el-table-column>
          <el-table-column prop="outsl" label="QTY(PCS)">
          </el-table-column>
          <el-table-column prop="mxsl" label="PCS/CTN">
          </el-table-column>
          <el-table-column prop="zxs" label="Total CTNS">
          </el-table-column>
          <el-table-column prop="mz" label="G.W./CTN">
          </el-table-column>
          <el-table-column prop="mz1" label="Total KGs">

          </el-table-column>
          <el-table-column prop="mz2" label="Total LBs">
          </el-table-column>
          <el-table-column label="Dimension(L*W*H)CM">
            <el-table-column prop="ccm" label="Length"/>
            <el-table-column prop="kcm" label="Width"/>
            <el-table-column prop="gcm" label="Height"/>
          </el-table-column>
          <!--<el-table-column prop="kcm" label="Dimension(L*W*H)CM">
          </el-table-column>
          <el-table-column prop="gcm" label="Dimension(L*W*H)CM">
          </el-table-column>-->
          <el-table-column prop="zjz" label="Total Volume(CBM)">
          </el-table-column>
          <el-table-column prop="pno" label="Radiance Lot No.">
          </el-table-column>
          <!--<el-table-column prop="remark" label="Remarks">
          </el-table-column>-->
        </el-table>
        <el-pagination class="mpage_dl" background layout="prev, pager, next" :current-page="currentPage"
                       :page-size="pageSize" :total="total" @current-change=page>
        </el-pagination>
      </div>
    </div>

</template>

<script>

export default {
  name: "Home",
  // 监控数组变化
  computed:{
    allDeparts:function (){
      //转为大写
      const searchValue = this.searches;
      if(searchValue){
        let treeData = this.departs;
        let handleTreeData = this.handleTreeData(treeData, searchValue.toUpperCase())
        this.setExpandRow(handleTreeData)
        return handleTreeData
      }
      return this.departs;
    }
  },
  data(){
    return {
      // 当前的面包顺序 // 默认是不显示
      isDisPlayProducts:false,
      currentName:"",
      //博客的
      currentPage: 1,
      total: 0,
      pageSize: 20,
      cIndex:0,
      number:0,
      form:{
        orderNumber:'',
      },
      user:{
        userId:"0",
        username:'',
        password:''
      },
      // 自定义order
      order:{
        id:0,
        jhdate:"",
        gno:"",
        gmodel:"",
        cno:"",
        outsl:"",
        mxsl:"",
        zxs:"",
        mz:"",
        mz1:"",
        mz2:"",
        ccm:"",
        kcm:"",
        gcm:"",
        zjz:"",
        pno:""
        //remark:""
      },
      expandRows:[],
      departs:[],
      // 新增搜索的 2022-9-9
      searches:'',
      columns:[
        {prop:'id',label: 'ID'},
        {prop:'date', label:'Planned shipping date'},
        {prop: 'order', label: 'Production order number'},
        {prop: 'number', label: 'Production number'},
        {prop: 'type', label: 'Customer Model No.'},
        {prop: 'quantity', label: 'Export quantity'},
        {prop: 'customer', label: 'Order number'},
        {prop: 'length', label: 'Outer box length'},
        {prop: 'width', label: 'Outer box width'},
        {prop: 'height', label: 'Outer box height'},
        {prop: 'amount', label: 'Amount per box'},
        {prop: 'gross', label: 'Gross weight'},
        {prop: 'net', label: 'Net weight'},
        {prop: 'single', label: 'Single net weight'},
        {prop: 'battery', label: 'battery model'},
        {prop: 'total', label: 'Total number boxes'},
        {prop: 'gross-weight', label: 'Gross weight'},
        {prop: 'total-weight', label: 'Total net weight'},
        {prop: 'cubic', label: 'Total cubic meter'},
        {prop: 'case-number', label: 'Case number'},
        {prop: 'ship', label: 'Whether to ship'},
      ],
      // 2022 12/21 新增
      dialogFormVisible:false,
      formLabelWidth:"150px",
      ruleForm: {
        oldpassword:'',
        password: '',
        cpassword:''
      },
      rules: {
        password: [
          { required: true, message: 'Please input Password', trigger: 'blur' },
          { min: 3, max: 15, message: 'The length is 3 to 15 char', trigger: 'blur' }
        ],
        cpassword: [
          { required: true, message: 'Please input Confirm Password', trigger: 'blur' },
          { min: 3, max: 15, message: 'The length is 3 to 15 char', trigger: 'blur' }
        ],
      }
    }
  },
  // 方法
  methods:{
    // 获取当前的页数
    page(currentPage){

      const _this = this;

      _this.$axios.get("guest/products?currentPage="+currentPage+"&currentSize="+_this.pageSize
          +"&userId="+_this.user.userId+"&gname="+_this.user.username+"&chok=NO",
          {headers:{
        "Authorization":localStorage.getItem("token")}})
      .then(res =>{
        if(res.data.data.records.length > 0 ) {
          const list = res.data.data.records;
          _this.cIndex = res.data.data.current;

          let cIndex = res.data.data.current;
          //_this.departs = [..._this.departs, ...list];
          if(_this.departs.length > 0){
            _this.departs = [];
          }

          let num = 0;

          for(let i=0; i<list.length; i++)
          {
            if(cIndex === 1){
              num = i + 1;
            }else{
              num = cIndex*10+i+1+((cIndex-1)*10-10);
            }
            // 进行数据处理
            let order = {};
            //const o = _this.order();
            order.id = num;
            order.jhdate = this.changesTime(list[i].jhdate);

            order.gno = list[i].gno;
            order.cno = list[i].cno;
            order.gmodel = list[i].gmodel;

            order.outsl = list[i].outsl;
            order.mxsl = list[i].mxsl;
            order.zxs = list[i].zxs;
            order.mz = this.numFilterMap(list[i].mz,1);
            order.mz1 =  this.numFilterMap(this.numCombine(list[i].zxs,list[i].mz),1);
            order.mz2 = this.numFilter(order.mz1 * 2.2);
            order.ccm = list[i].ccm;
            order.kcm = list[i].kcm;
            order.gcm = list[i].gcm;
            order.zjz = list[i].zjz;
            order.pno = list[i].pno;
            order.remark = "";

            _this.departs.push(order);
          }

          _this.currentPage = res.data.data.currentPage;
          _this.total = res.data.data.total;
          _this.pageSize = res.data.data.size;

        }else{
          this.$message.error('Is empty data!');
        }
      });
    },
    // 自动计算id
    computerId(index){
      this.number = index+1;
      // if(list.length > 0){
      //   for(let i =0; i<list.length; i++){
      //     if(this.cIndex === 1){
      //       this.number = i + 1;
      //     }else{
      //       this.number = this.cIndex*10+i+1+((this.cIndex-1)*10-10);
      //     }
      //   }
      // }
      return this.number;
    },
    // 搜索数据
    search(currentPage){
      const _this = this;
      _this.$axios.get("guest/products?currentPage="+currentPage+"&currentSize="+_this.pageSize
          +"&userId="+_this.user.userId+"&gname="+_this.user.username+"&chok=NO",
          {headers:{
              "Authorization":localStorage.getItem("token")}})
          .then(res =>{
            if(res.data.data.records.length > 0) {
              _this.departs = res.data.data.records;
              console.log("length is:"+JSON.stringify(_this.departs.length));
            }else{
              console.log("读取数据错误");
            }
            _this.currentPage = res.data.data.currentPage;
            _this.total = res.data.data.total;
            _this.pageSize = res.data.data.size;
          });
    },

    // 搜索数据
    searchNo(){

    },

    getRowKeys(row) {
      return row.id;
    },
    clearTable(){
      return this.departs;
    },
    //  树形表格过滤
    handleTreeData(treeData, searchValue) {
      if (!treeData || treeData.length === 0) {
        return [];
      }
      const array = [];
      for (let i = 0; i < treeData.length; i += 1) {
        let match = false;
        for(let pro in treeData[i]){
          if(typeof(treeData[i][pro])=='string'){
            match |= treeData[i][pro].includes(searchValue);
            if(match) break;
          }
        }
        if (this.handleTreeData(treeData[i].children, searchValue).length > 0 || match ) {
          array.push({
            ...treeData[i],
            children: this.handleTreeData(treeData[i].children, searchValue),
          });
        }
      }
      return array;
    },
    // 将过滤好的树形数据展开
    setExpandRow(handleTreeData) {
      if(handleTreeData.length) {
        for (let i of handleTreeData) {
          this.expandRows.push(i.id)
          if(i.children.length) {
            this.setExpandRow(i.children)
          }
        }
      }
    },

    // 固定行 表示总和
    getSummaries(param){
      if(this.departs.sum !== null){
        // , data
        const { columns, data} = param;
        const sums = [];
        //const arr = ['mz1', 'mz2'];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = 'Total';
            return;
          }
          const values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
             // console.log(prev)
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = parseFloat(sums[index]).toFixed(2);
            sums[index] += ' ';
          } else {
            sums[index] = 'N/A';
          }
        });

        return sums;

      }
    },

    exportExcel(){
      require.ensure([], () => {
        const {
          export_json_to_excel
        } = require("../excel/Export2Excel");
        const tHeader = ["ID", "Planned shipping date", "Order No.", "Customer Model No.", "Radinace Model No.", "QTY(PCS)",
          "PCS/CTN","Total CTNS", "G.W./CTN","Total KGs","Total LBs","Dimension(Length) CM",
        "Dimension(Width) CM","Dimension(Height) CM","Total Volume(CBM)","Radiance Lot No."]; // 这是excel文档第一行显示的标题
        const filterVal = ["id", "jhdate", "gno", "gmodel", "cno", "outsl", "mxsl", "zxs",
        "mz","mz1","mz2","ccm","kcm","gcm","zjz","pno"]; // id，version等都是上面标题所对应的数据
        const list = this.departs; //把data里的数组tableData存到list中
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "Home Table Excel"); //标题，数据，文件名
      });
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));

    },

    // 时间处理
    changesTime(date) {
      if(date != null) {
        const json_date = new Date(date);
        const monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug",
          "Sep", "Oct", "Nov", "Dec"];
        const month = json_date.getMonth();
        let day = json_date.getDate();
        if (day.toString().length === 1) {
          day = "0" + day.toString();
        }
        return day + " " + monthArray[month] + " " + json_date.getFullYear()
      }else{
        return "Unconfirmed"
      }
    },

    // 保留2位小数
    numFilter(value){
      return parseFloat(value).toFixed(2);
    },

    numFilterMap(value,num){
      return parseFloat(value).toFixed(num);
    },

    // 组合数据 保留2位小数
    numCombine(value1,value2){
      if(value1 == null){
        value1 = 0;
      }

      if(value2 == null){
        value2 = 0;
      }

      return value1*value2;
    },

    // 面包中点击
    breadClick(){
      if(this.$route.path !== "/home"){
        return "/home"
      }
      return ""
    },

    /**
     * 调用浏览器的打印方法
     */
    handlePrint() {
      window.print()
    },

    /**
     * 调用修改密码弹窗
     */
    modifyDialogShow(){
      this.dialogFormVisible = true
    },

    submitForm(formName) {
      this.dialogFormVisible = false

      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this

          if(_this.ruleForm.password === _this.ruleForm.cpassword) {

            const updatePwd = {
              "username":_this.user.username,
              "password":_this.ruleForm.password
            }

            if(_this.ruleForm.oldpassword === _this.user.password) {
              _this.$axios.post("user/modifyPwd", updatePwd, {
                headers: {
                  "Authorization": localStorage.getItem("token")
                }
              }).then(
                  res => {
                    console.log(res.data.username)
                    this.$message.success("Modify success")
                  }
              )
            }else{
              this.$message.error("The old password error!")
            }
          }else{
            this.$message.error('The confirm password need as seem as compare password !');
          }
        } else {
          this.$message.error('The account or password error!');
        }
      });
    },

    // 退出登录
    loginOut(){
      this.$axios.get("user/logout",{headers:{
          "Authorization":localStorage.getItem("token")}}).then(
          res =>{
            console.log(res.data.message)
            //this.$message.success(res.data.message)

            // 跳转到登录界面
            this.$router.push("/login");
          }
      )
    },

    goHome(){
      this.$router.push("/home");
    },

    goProducts(){
      this.$router.push("/products");
    },

  },

  created() {
    // 获取当前的名称
    this.currentName = "Home"

    if(this.$store.getters.getUser.id){
      this.user.userId = this.$store.getters.getUser.id
      this.user.username = this.$store.getters.getUser.username
      // 获取密码
      this.user.password = this.$store.getters.getUser.password

      // 必须为tmw才能显示
      if(this.user.username === "tmw"){
        this.isDisPlayProducts = true
      }

      // 获取数据
      this.page(1);
    }
  }
}


/*

  备份代码 区块 备份时间 2022/9/9
  this.oldDeparts = this.departs;
      //let news = [];
      if (value != null) {
        if(this.departs.length > 0) {
          for(let i=0; i<this.departs.length; i++){
            if(value === this.departs[i].gno){
              this.newDeparts.push(this.departs[i])
            }
          }
          // 查询完成然后结束显示
          this.departs = this.newDeparts;
        }
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          //alert('submit!');
          const _this = this;
          this.$axios.post("/login",this.ruleForm).then(
              res =>{
                const jwt = res.headers['authorization'];
                const userInfo = res.data.data;
                //console.log(userInfo)

                // 把数据共享出去
                _this.$store.commit("SET_TOKEN", jwt);
                _this.$store.commit("SET_USERINFO", userInfo);
                _this.$router.push("/home");
              }
          )
        } else {

          return false;
        }
      });

      ---------------------------
       // const data1 = {
      //   "currentPage": currentPage,
      //   "currentSize": _this.pageSize,
      //   "userId": _this.user.userId,
      //   "gname": _this.user.username,
      //   "chok": "NO"
      // };

      //console.log("data is"+JSON.stringify(data1));

 */


</script>

<style scoped>

.content{
  width: 100%;
  margin: 0;
  padding: 0;
}

.head{
  width: 100%;
}

.bread{
  width: 100%;
  height: 60px;
  background-color: #E1E1E1;
  float: left;
  -ms-flex: 1;
  flex: 1;
}

.bread .bread_left{
  width: 25%;
  height: 60px;
  line-height: 60px;
  margin-left: 20px;
  color:#323232;
  font-weight: normal;
  float: left;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.bread .bread_right{
  width: 40%;
  height: 60px;
  line-height: 60px;
  float: right;
  font-weight: normal;
  font-size: 16px;
  text-align: right;
  margin-right: 20px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.query{
  width: 100%;
  margin-top: 30px;
  float: left;
}

.query .search{
  width: 90%;
  text-align: right;
  margin-right: 20px;
}

.query .excel{
  width: 90%;
  text-align: center;
}

.currenBtn:hover {
  opacity: 0.8;
}

.query .chart{
  width: 100%;
}

.mpage_dl {
  margin: 0 auto;
  text-align: center;
}

.chart{
  padding: 8px;
}

.bread .bread_left .bread_el{
  text-align: center;
  line-height: 60px;
}

@media print {
  @page {
    /* 纵向打印 */
    size: portrait;

    /* 横向打印 */
    /*size: landscape;*/
    /* 去掉页眉页脚*/
    margin-top: 0;
    margin-bottom: 0;
  }

  /*打印不显示打印按钮*/
  .print-button-container {
    display: none !important;
  }

  /*页数不打印*/
  .mpage_dl {
    display: none !important;
  }

  .bread {
    display: none !important;
  }

  .query {
    display: none !important;
  }
}




</style>


