import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// eslint-disable-next-line no-unused-vars
import {formatTime} from './utils'

// 布局代码
import Element from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"

// 网络请求
import axios from 'axios'

import "./axios"

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import './permission.js' // 路由拦截

//import Blob from "./excel/Blob";
//import Export2Excel from "./excel/Export2Excel";

Vue.config.productionTip = false;

Vue.use(Element);

Vue.use(mavonEditor);

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.prototype.$EventBus = new Vue();
Vue.prototype.$formatTime = formatTime();



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

