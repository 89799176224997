//时间格式处理
// export default {
//     formatTime(number) {
//         let time = new Date(number);
//         return time.getMonth() + '/' + time.getDate() + '/' + time.getFullYear() + " " + time.getHours() + ":" + time.getMinutes();
//     }
// }

export function formatTime(number) {
    let time = new Date(number);
    return time.getMonth() + '/' + time.getDate() + '/' + time.getFullYear() + " " + time.getHours() + ":" + time.getMinutes();
}


// export function changeTime(date) {
//     const json_date = new Date(date);
//     const monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug",
//         "Sep", "Oct", "Nov", "Dec"];
//     const month = json_date.getMonth();
//     let day = json_date.getDate();
//     if (day.toString().length === 1) {
//         day = "0" + day.toString();
//     }
//     return day + " " + monthArray[month] + " " + json_date.getFullYear()
//     //return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
// }