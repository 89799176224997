<template>
  <div class="content">
    <!--内容显示-->
    <div class="top_line" id="top_line" style="display: none;">
      <p>
        <span id="top_span_code"></span>
      </p>
    </div>
    <div class="head">
      <div>
        <div class="loginLeftName">
          <img src="../assets/radianceImage.png"  alt="picture"/>
        </div>
      </div>
    </div>
    <div class="wrap" id="wrap">
      <div class="logGet">

        <div class="logD logDtip">
          <p class="p1">Welcome to login</p>
        </div>
<!--        <div class="lgD">-->
<!--          <img src="../assets/zhanghu.png" width="20" height="20" alt="" />-->
<!--          <input type="text" id="input_Account" placeholder="Enter your user name" maxlength="10" />-->
<!--        </div>-->
        <div class="lgD">
          <!-- 输入框 -->
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="Account">
              <el-input v-model="ruleForm.username"></el-input>
            </el-form-item>
<!--            prop="password"-->
            <el-form-item label="PassWord">
              <el-input type="password" v-model="ruleForm.password"></el-input>
            </el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')" style="width: 100%; background-color: #ee7700; border: #ee7700; height: 45px;">Login</el-button>
<!--            <el-button @click="resetForm('ruleForm')">重置</el-button>-->
          </el-form>
          <span style="float: right; margin:5px;"> 0.0.1 </span>
        </div>
      </div>
    </div>
    <div class="footer" id="foot" style="display: none;">
      <div class="copyright" style="color: #265A88;width: 100%;">
        <p>Copyright © 2021 Huizhou Liheng Plastic Electronics Co., Ltd.</p>
        <div class="img">
          <!--<i class="icon1"></i>-->
          <span>contact address：contact Address: Dajing Industrial Park, Luoyang Town, Boluo County, Huizhou
						City, Guangdong Province</span>
        </div>
      </div>
    </div>

    <div class="footer" style="display:none">
      <p>Radiance Instruments Ltd</p>
      <p>Copyright © 2022.Radiance Instruments Ltd. All Rights Reserved.</p>
    </div>

  </div>

</template>


<script>
import "@/assets/css/index.css"
//import config from 'package.json'

export default {
  name: "login",
  data() {
    return {
      ruleForm: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          { required: true, message: 'Please input Account', trigger: 'blur' },
          { min: 3, max: 15, message: 'The length is 3 to 15 char', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please input Password', trigger: 'blur' },
          { min: 3, max: 15, message: 'The length is 3 to 15 char', trigger: 'blur' }
        ],
      },

      //当前的版本号
      version:""
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this;
          this.$axios.post("user/login",this.ruleForm).then(
              res =>{
                const jwt = res.headers['authorization'];
                const userInfo = res.data.data;
                // 把数据共享出去
                _this.$store.commit("SET_TOKEN", jwt);
                _this.$store.commit("SET_USERINFO", userInfo);
                _this.$router.push("/main");
              }
          )
        } else {
          this.$message.error('The account or password error!');
          return false
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  created() {
    // this.$axios.get('http://www.radiance-inst.com:8292/rilcompanyerpserver/package.json')
    // .then(res => {
    //   this.version = res.data.version
    // });
    // console.log("version is: "+this.version)
  }
}

</script>

<style scoped>

.content{
  width: 100%;
  float: left;
}

.demo-ruleForm{
  width: 100%;
  align-content: center;
}


/*.head {*/
/*  margin: 20px;*/
/*  height: 120px;*/
/*  width: 100%;*/
/*}*/

/*.head-img {*/
/*  width: 459px;*/
/*  height: 70px;*/
/*}*/

/*.head-span1 {*/
/*  height: 30px;*/
/*  font-size: 25px;*/
/*  color: #002A80;*/
/*}*/

/*.head-span2 {*/
/*  height: 30px;*/
/*  font-size: 25px;*/
/*  color: #C0A16B;*/
/*}*/

/*.head-span3 {*/
/*  height: 30px;*/
/*  font-size: 25px;*/
/*  color: #59C2E6;*/
/*}*/

/*.wrap {*/
/*  float: left;*/
/*  width: 100%;*/
/*  height: 719px;*/
/*  background-image: url(../assets/home.jpeg);*/
/*  background-repeat: no-repeat;*/
/*  background-position: center center;*/
/*  position: relative;*/
/*  background-size: 100%;*/
/*  text-align: center;*/
/*}*/


/*.logGet{*/
/*  float: right;*/
/*  margin: 30px;*/
/*  height: 358px;*/
/*  width: 368px;*/
/*  font-size: 28px;*/
/*  background-color: #fff;*/
/*  text-align: center;*/
/*}*/

/*.logGet .demo-ruleForm{*/
/*  width: 80%;*/
/*  text-align: center;*/
/*}*/


.top_line {
  height: 50px;
  width: 100%;
  background-color: #953B39;
}

.top_line p {
  width: 100%;
  margin: 0 auto;
  line-height: 30px;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.top_line p span {
  position: absolute;
  margin-top: 10px;
  font-size: 15px;

}

.top_line p a {
  float: right;
  margin-top: 12px;
}

.top_line p a img {
  vertical-align: middle;
  margin-right: 5px;

}



/*.logGet .logD.logDtip .p1 {*/
/*display: inline-block;*/
/*font-size: 28px;*/
/*margin-top: 30px;*/
/*}*/

.footer {
  float: left;
  width: 100%;
  height: 120px;
  background-color: #F2FBFF;
  position: relative;
  text-align: center;
}

</style>
