// 网络请求
import axios from 'axios'
import Element from 'element-ui'
import store from './store'
import router from './router'

//http://www.radiance-inst.com:8292/rilcompanyerpserver

axios.defaults.baseURL="http://www.radiance-inst.com:8292/rilcompanyerpserver/api/v1";
//axios.defaults.baseURL="http://183.63.23.116:8985/rilcompanyerpserver/api/v1";

//axios.defaults.baseURL="http://192.168.0.37:8080/api/v1";
//axios.defaults.testURL = "http://192.168.0.37:8080/api/v1"

// 前置拦截
axios.interceptors.request.use(config => {
    return config
});

axios.interceptors.response.use(response => {
        const res = response.data;
        //console.log("后置拦截");
        // 当结果的code是否为200的情况
        if (res.code === 200) {
            return response
        } else {
            // 弹窗异常信息
            Element.Message({
                message: response.data.message,
                type: 'error',
                duration: 2 * 1000
            });
            // 直接拒绝往下面返回结果信息
            return Promise.reject(response.data.message)
        }
    },
    error => {
        console.log('err' + error);// for debug
        // eslint-disable-next-line no-irregular-whitespace
        if(error.response.data) {
            // eslint-disable-next-line no-irregular-whitespace
            error.message = error.response.data.message
        }
        // 根据请求状态觉得是否登录或者提示其他
        if (error.response.status === 401) {
            store.commit('REMOVE_INFO');
            router.push({
                path: '/login'
            });
            error.message = 'Please log in again';
        }
        if (error.response.status === 403) {
            error.message = 'Insufficient permissions to access';
        }
        Element.Message({
            message: error.message,
            type: 'error',
            duration: 3 * 1000
        });
        return Promise.reject(error)
});
