<template>
  <div class="chart">
    <div class="print-button-container" style="float: right; margin-right: 50px; margin-bottom: 10px; margin-top: 10px;">
      <el-button type="primary" icon="el-icon-document" @click="exportExcel">Excel</el-button>
    </div>
    <el-table :data="allDeparts"
              :header-cell-style="{'text-align':'center'}"
              :cell-style="{'text-align':'center'}"
              border style="width: 100%; text-align: center" id=""
              :row-key="getRowKeys"
              :expand-row-keys="expandRows"
              :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <template slot="empty">
        <el-empty :image-size="100" description="No data"/>
      </template>

      <el-table-column prop="id" label="ID">
      </el-table-column>
      <el-table-column prop="gno" label="PO No.">
      </el-table-column>
      <el-table-column prop="pi" label="PI No.">
      </el-table-column>
      <el-table-column prop="gmodel" label="TMW mode No.">
      </el-table-column>
      <el-table-column prop="cno" label="Radiance Model No.">
      </el-table-column>
      <el-table-column prop="sl" label="QTY">
      </el-table-column>
      <el-table-column prop="crq" label="Estimated shipping date">
      </el-table-column>
      <el-table-column prop="pno" label="Radiance lot no.">
      </el-table-column>

      <!--新增的字段-->
      <el-table-column label="Production Status">
        <el-table-column prop="spook" label="Materials Ready">
          <template slot-scope="scope">
            <el-image style="width: 20px; height: 20px;" :src="handleImage(scope.row.spook)"/>
          </template>
        </el-table-column>
        <el-table-column prop="ssc" label="In Production">
          <template slot-scope="scope">
            <el-image style="width: 20px; height: 20px;" :src="handleImage(scope.row.ssc)"/>
          </template>
        </el-table-column>

        <el-table-column prop="stest" label="Under Testing">
          <template slot-scope="scope">
            <el-image style="width: 20px; height: 20px;" :src="handleImage(scope.row.stest)"/>
          </template>
        </el-table-column>

        <el-table-column prop="stok" label="Complete Testing">
          <template slot-scope="scope">
            <el-image style="width: 20px; height: 20px;" :src="handleImage(scope.row.stok)"/>
          </template>
        </el-table-column>
      </el-table-column>

    </el-table>
    <el-pagination class="mpage_dl" background layout="prev, pager, next" :current-page="currentPage"
                   :page-size="pageSize" :total="total" @current-change=page>
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Production",
  // 监控数组变化
  computed:{
    allDeparts:function (){
      //转为大写
      const searchValue = this.searches;
      if(searchValue){
        let treeData = this.departs;
        let handleTreeData = this.handleTreeData(treeData, searchValue.toUpperCase())
        this.setExpandRow(handleTreeData)
        return handleTreeData
      }
      return this.departs;
    }
  },
  data() {
    return {
      // 当前的面包顺序
      currentName: "",
      //博客的
      currentPage: 1,
      cIndex: 0,
      total: 0,
      pageSize: 20,
      form: {
        orderNumber: '',
      },
      user: {
        userId: "0",
        username: '',
        password: ''
      },
      products: {
        id: 0,
        pi: "",
        sl: 0,
        crq: "",
        ch: "",
        lx: "",
        gmodel: "",
        gno: "",
        cno: "",
        gname: "",
        pno: "",
        // 2022/12/15新增4个字段
        spook: "",
        ssc: "",
        stest: "",
        stok: "",

        spookcheck: "",
        ssccheck: "",
        stestcheck: "",
        stokcheck: ""

      },
      departs: [],
      expandRows: [],
    }
  },
  methods: {
    page(currentPage) {
      const _this = this;

      _this.$axios.get("product/list?currentPage=" + currentPage + "&currentSize="
          + _this.pageSize + "&userId=" + _this.user.userId + "&gName=" + _this.user.username + "&lx=scd",
          {
            headers: {
              "Authorization": localStorage.getItem("token")
            }
          }
      ).then(res => {
        if (res.data.data.records.length > 0) {

          // 获取网络数据
          const list = res.data.data.records

          //console.log("第一次读取数据: "+JSON.stringify(list))

          _this.cIndex = res.data.data.current;

          let cIndex = res.data.data.current;


          if (_this.departs.length > 0) {
            _this.departs = [];
          }

          let num = 0;
          for (let i = 0; i < list.length; i++) {
            if (cIndex === 1) {
              num = i + 1;
            } else {
              num = cIndex * 10 + i + 1 + ((cIndex - 1) * 10 - 10);
            }

            let product = {}
            product.id = num
            product.gno = list[i].gno
            product.pi = list[i].pi
            product.sl = list[i].sl
            product.crq = this.changesTime(list[i].crq)
            product.ch = list[i].ch
            product.lx = list[i].lx
            product.gmodel = list[i].gmodel
            product.cno = list[i].cno
            product.pno = list[i].pno
            product.spook = list[i].spook
            product.ssc = list[i].ssc
            product.stest = list[i].stest
            product.stok = list[i].stok

            _this.departs.push(product);
          }

          console.log("读取数据:" + _this.departs.length);

          _this.currentPage = res.data.data.currentPage;
          _this.total = res.data.data.total;
          _this.pageSize = res.data.data.size;

        } else {
          this.$message.error('Is empty data!');
        }
      })
    },

    // 时间处理
    changesTime(date) {
      if (date != null) {
        const json_date = new Date(date);
        const monthArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug",
          "Sep", "Oct", "Nov", "Dec"];
        const month = json_date.getMonth();
        let day = json_date.getDate();
        if (day.toString().length === 1) {
          day = "0" + day.toString();
        }
        return day + " " + monthArray[month] + " " + json_date.getFullYear()
      } else {
        return "Unconfirmed"
      }
    },

    getRowKeys(row) {
      return row.id;
    },
    clearTable() {
      return this.departs;
    },

    //  树形表格过滤
    handleTreeData(treeData, searchValue) {
      if (!treeData || treeData.length === 0) {
        return [];
      }
      const array = [];
      for (let i = 0; i < treeData.length; i += 1) {
        let match = false;
        for (let pro in treeData[i]) {
          if (typeof (treeData[i][pro]) == 'string') {
            match |= treeData[i][pro].includes(searchValue);
            if (match) break;
          }
        }
        if (this.handleTreeData(treeData[i].children, searchValue).length > 0 || match) {
          array.push({
            ...treeData[i],
            children: this.handleTreeData(treeData[i].children, searchValue),
          });
        }
      }
      return array;
    },
    // 将过滤好的树形数据展开
    setExpandRow(handleTreeData) {
      if (handleTreeData.length) {
        for (let i of handleTreeData) {
          this.expandRows.push(i.id)
          if (i.children.length) {
            this.setExpandRow(i.children)
          }
        }
      }
    },
    // 面包中点击
    breadClick() {
      if (this.$route.path !== "/products") {
        this.$router.push("/home");
      }
    },

    exportExcel() {
      require.ensure([], () => {
        const {
          export_json_to_excel
        } = require("../excel/Export2Excel");
        const tHeader = ["ID", "PO NO.", "PI No.", "TMW Model No.", "Radinace Model No.", "QTY",
          "Estimated shipping date", "Radiance Iot No.", "Materials Ready", "In Production", "Under Testing", "Complete Testing"]; // 这是excel文档第一行显示的标题
        const filterVal = ["id", "gno", "pi", "gmodel", "cno", "sl", "crq", "pno", "spook", "ssc", "stest", "stok"]; // id，version等都是上面标题所对应的数据
        const list = this.departs; //把data里的数组tableData存到list中
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "In Production Table Excel"); //标题，数据，文件名
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },

    /**
     * 调用处理程序
     */
    handleImage(value) {
      if (value === "YES") {
        return require("../assets/selected.png")
      } else {
        return require("../assets/unselect.png")
      }
    },
  },

  created() {
    // 获取当前的名称
    this.currentName = "Products"

    if(this.$store.getters.getUser.id){
      this.user.userId = this.$store.getters.getUser.id
      this.user.username = this.$store.getters.getUser.username
      // 获取密码
      this.user.password = this.$store.getters.getUser.password
      // 获取数据
      this.page(1)
    }
  }

}
</script>

<style scoped>
.query .chart{
  width: 100%;
}

.mpage_dl {
  margin: 0 auto;
  text-align: center;
}

.chart{
  padding: 8px;
}
</style>