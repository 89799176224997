<template>
  <!--面包顺-->
  <div class="bread">
    <div class="bread_left">
      <el-button accordion @click="handleSelectClick('Shipment')" plain :style="style1">Shipment</el-button>

      <el-button :style="style2" accordion @click="handleSelectClick('Production')" plain v-show="isDisPlayProducts" >In Production</el-button>

    </div>
    <div class="bread_right">

      <el-button @click="modifyDialogShow" plain>Change Password</el-button>
      &nbsp;&nbsp;<el-button  @click="loginOut" plain>LogOut</el-button>

      <el-dialog title="Change Password" :visible.sync="dialogFormVisible" center width="30%">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item label="Old Password" :label-width="formLabelWidth">
            <el-input v-model="ruleForm.oldpassword"></el-input>
          </el-form-item>
          <el-form-item label="New Password" :label-width="formLabelWidth">
            <el-input v-model="ruleForm.password"></el-input>
          </el-form-item>
          <el-form-item label="Confirm Password" :label-width="formLabelWidth">
            <el-input v-model="ruleForm.cpassword"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">Cancel</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">Confirm</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import {
  EventBus
} from "../event-bus";


export default {
  name: "Bread",
  data(){
    return {

      // 当前的面包顺序 // 默认是不显示
      isDisPlayProducts:false,
      // 2022 12/21 新增
      dialogFormVisible:false,
      formLabelWidth:"150px",
      ruleForm: {
        oldpassword:'',
        password: '',
        cpassword:''
      },

      rules: {
        password: [
          { required: true, message: 'Please input Password', trigger: 'blur' },
          { min: 3, max: 15, message: 'The length is 3 to 15 char', trigger: 'blur' }
        ],
        cpassword: [
          { required: true, message: 'Please input Confirm Password', trigger: 'blur' },
          { min: 3, max: 15, message: 'The length is 3 to 15 char', trigger: 'blur' }
        ],
      },

      data: [
        {
          label:'Shipment'
        },
        {
          label: 'Production'
        }
      ],

      defaultProps: {
        label: 'label'
      },

      user:{
        userId:"0",
        username:'',
        password:''
      },
      isSelectA:false,
      isSelectB:true,
      style1:'',
      style2:'',
    }
  },

  //border-color:blue;color:blue
  //border-color:grey;color:grey

  methods:{
    /**
     * 调用修改密码弹窗
     */
    modifyDialogShow(){
      this.dialogFormVisible = true
    },

    submitForm(formName) {
      this.dialogFormVisible = false

      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this

          if(_this.ruleForm.password === _this.ruleForm.cpassword) {

            const updatePwd = {
              "username":_this.user.username,
              "password":_this.ruleForm.password
            }

            if(_this.ruleForm.oldpassword === _this.user.password) {
              _this.$axios.post("user/modifyPwd", updatePwd, {
                headers: {
                  "Authorization": localStorage.getItem("token")
                }
              }).then(
                  res => {
                    console.log(res.data.username)
                    this.$message.success("Modify success")
                  }
              )
            }else{
              this.$message.error("The old password error!")
            }
          }else{
            this.$message.error('The confirm password need as seem as compare password !');
          }
        } else {
          this.$message.error('The account or password error!');
        }
      });
    },

    // 退出登录
    loginOut(){
      this.$axios.get("user/logout",{headers:{
          "Authorization":localStorage.getItem("token")}}).then(
          res =>{
            console.log(res.data.message)
            //this.$message.success(res.data.message)

            // 跳转到登录界面
            this.$router.push("/login");
          }
      )
    },

    handleSelectClick(data) {

      if(data === "Shipment"){
        this.style1 = "border-color:rgb(48,145,251);color:rgb(48,145,251)"
        this.style2 = "color:#606266"
      }else{
        this.style2 = "border-color:rgb(48,145,251);color:rgb(48,145,251)"
        this.style1 = "color:#606266"
      }

      EventBus.$emit("selectClick", {
       selectClickData: data
      });
    },
  },

  created() {
    this.style1 = "border-color:rgb(48,145,251);color:rgb(48,145,251)"
    this.style2 = "color:#606266"
    // 获取当前的名称
    this.currentName = "Bread"

    if (this.$store.getters.getUser.id) {
      this.user.userId = this.$store.getters.getUser.id
      this.user.username = this.$store.getters.getUser.username
      // 获取密码
      this.user.password = this.$store.getters.getUser.password

      // 必须为tmw才能显示
      if (this.user.username === "tmw") {
        this.isDisPlayProducts = true
      }
    }
  }

}
</script>



<style scoped>

.bread{
  width: 100%;
  height: 60px;
  background-color: #E1E1E1;
  float: left;
  -ms-flex: 1;
  flex: 1;
}

.bread .bread_left{
  width: 25%;
  height: 60px;
  line-height: 60px;
  margin-left: 20px;
  color:#323232;
  font-weight: normal;
  float: left;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.bread .bread_right{
  width: 40%;
  height: 60px;
  line-height: 60px;
  float: right;
  font-weight: normal;
  font-size: 16px;
  text-align: right;
  margin-right: 20px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/*.currenBtn:hover {*/
/*  opacity: 0.8;*/
/*}*/


</style>