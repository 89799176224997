import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Products from "../views/Products";
import Main from  "../views/Main"
//import App from '../App'

Vue.use(VueRouter);

const routes = [
    {
        path:'/',
        name:'/',
        redirect:'/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/home',
        name: 'Home',
        component: Home
    },
    {
        path: '/products',
        name: 'Products',
        component: Products
    },
    {
        path: '/main',
        name: 'Main',
        component: Main
    }
];

const router = new VueRouter({
  //mode: 'history',// hash去掉#，需要路由模式改为history
  //base: '/dist/', // 这个配置也很重要，否则会出现页面空白情况
    mode: 'hash',
    base: process.env.baseURL, // baseURL
  routes
});

export default router
